import * as actions from './options.action';
import { takeEvery, put, call } from 'redux-saga/effects';
import { getAllTags } from 'api/api-tags';
import { getAllModelHomes } from 'api/api-model-homes';

function* getAllTagsSagaRequest() {
	try {
		const response:{body:any} = yield call(getAllTags);
		yield put(actions.setAllTags(response.body));
	} catch (e) {
		console.log(e);
	}
}


export default function* watchAuthSaga() {
	yield takeEvery(actions.getAllTagsSaga, getAllTagsSagaRequest);
}
