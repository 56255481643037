import { UserType } from 'modules/types';
import { createReducer } from 'redux-act';
import * as actions from './auth.action';

const initialState: UserType = {
	display_name: '',
	user_email: '',
	user_login: '',
	user_nicename: '',
	user_registered: '',
	user_status: 0,
	user_token: localStorage.getItem('user_token') || undefined,
	tags:[]
};

const reducer:any = {
	[actions.setProfile]: (state: any, data: { profile: UserType }) => {
		const { profile } = data;
		//save token in local storage
		if (profile.user_token) localStorage.setItem('user_token', profile.user_token);
		return { ...profile };
	},
	[actions.signOut]: (state: any) => {
		//save token in local storage
		// if (profile.user_token) localStorage.setItem('user_token', profile.user_token);
		localStorage.clear();
		const profile = initialState;
		return { ...profile, user_token:undefined };
	},
	[actions.setProductTags]: (state: any, data: {tags: any[]}) => {
		const {tags} = data;
		return { ...state, tags };
	},
};

export default createReducer(reducer, initialState);
