import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StateType, UserType } from 'modules/types';
import * as authAction from './auth.action';

const useAuth = () => {
	const dispatch = useDispatch();

	const profile: UserType = useSelector((state: StateType) => {
		return state.authReducer;
	});

	const onSignin = useCallback(
		(payload:any) => {
			dispatch(authAction.signInRequestSaga(payload));
		},
		[dispatch],
	);

	const onSignOut = useCallback(() => {
		dispatch(authAction.signOut());
	}, [dispatch]);

	const loginByToken = useCallback(() => {
		dispatch(authAction.loginByTokenSaga());
	}, [dispatch]);

	const getProductTagsSaga = useCallback(() => {
		dispatch(authAction.getProductTagsSaga());
	}, [dispatch]);

	return { profile, onSignin, loginByToken, onSignOut, getProductTagsSaga };
};

export default useAuth;