import { getRequest, postRequest, putRequest } from './requests';
import config from './config';

export const sigin = (payload) => {
	return postRequest(`${config.baseURL}auth/login`, payload);
};

export const loginByToken = () => {
	return postRequest(`${config.baseURL}auth/loginByToken`);
};
