import { deleteRequest, getRequest, postRequest, putRequest } from './requests';
import config from './config';

export const getAll = (offset=0, limit=20, query='') => {
	return getRequest(`${config.baseURL}materials/`);
};

export const createMaterial = (payload) => {
	return postRequest(`${config.baseURL}materials/`, payload);
};

export const deleteMaterial = (id) => {
	return deleteRequest(`${config.baseURL}materials/${id}`);
};

export const updateMaterial = (id, payload) => {
	return putRequest(`${config.baseURL}materials/${id}`, payload);
};

export const getMaterialByid = (id) => {
	return getRequest(`${config.baseURL}materials/ids/${id}`);
};

export const addBulkMaterials = (payload) => {
	return postRequest(`${config.baseURL}materials/bulk/`, payload);
};