import { deleteRequest, getRequest, postRequest, putRequest } from './requests';
import config from './config';

export const getAll = (offset=0, limit=20, query='') => {
	return getRequest(`${config.baseURL}users?offset=${offset}&limit=${limit}&query=${query}`);
};

export const createUser = (payload) => {
	return postRequest(`${config.baseURL}users/`, payload);
};

export const deleteUser = (id) => {
	return deleteRequest(`${config.baseURL}users/${id}`);
};

export const updateUser= (id, payload) => {
	return putRequest(`${config.baseURL}users/${id}`, payload);
};

export const getUserDetailById = (id) => {
	return getRequest(`${config.baseURL}users/ids/${id}`);
};