import { createReducer } from 'redux-act';
import * as actions from './options.action';

const initialState = {
	tags: [],
};

const reducer:any = {
	[actions.setAllTags]: (state: any, data: {tags: any[]}) => {
		const {tags} = data;
		return { ...state, tags };
	},
};

export default createReducer(reducer, initialState);
