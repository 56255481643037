import { deleteRequest, getRequest, postRequest, putRequest } from './requests';
import config from './config';

export const getAllModelHomes = (offset, limit, query) => {
	return getRequest(`${config.baseURL}modelhomes/`);
};

export const createModelHome = (payload) => {
	return postRequest(`${config.baseURL}modelhomes/`, payload);
};

export const deleteModelHome = (id) => {
	return deleteRequest(`${config.baseURL}modelhomes/${id}`);
};

export const updateModelHome = (id, payload) => {
	return putRequest(`${config.baseURL}modelhomes/${id}`, payload);
};

export const getModelHomeById = (id) => {
	return getRequest(`${config.baseURL}modelhomes/ids/${id}`);
};

export const updateOrderids = (payload) => {
	return putRequest(`${config.baseURL}modelhomes/order/update`, payload);
};
