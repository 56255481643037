import { BalustradePriceItemType, HouseType, StainPriceItemType, StairPriceItemType, UpgradePriceItemType } from 'modules/types';

export const formatPrice = (price: number) => {
	return price.toLocaleString('en-AU', {
		style: 'currency',
		currency: 'AUD',
		minimumFractionDigits: 2,
	});
};

const isPropValuesEqual = (subject:any, target:any, propNames:any[]) => propNames.every(propName => subject[propName] === target[propName]);

export const getUniqueItemsByProperties = (items:any[], propNames:any[]) => {
	const propNamesArray = Array.from(propNames);
	return items.filter((item, index, array) => index === array.findIndex(foundItem => isPropValuesEqual(foundItem, item, propNamesArray)));
	// const uniqueArray = items.filter((thing, index) => {
	// 	const _thing = JSON.stringify(thing).replace(/ /g, '');
	// 	return index === items.findIndex(obj => {return JSON.stringify(obj).replace(/ /g, '') === _thing;});
	// });
	// return uniqueArray;
};


export const isSameColourGroup = (color_one:string, color_two:string) => {
	color_one = color_one.toLowerCase();
	color_two = color_two.toLowerCase();
	if(color_one == color_two){
		return true;
	}

	const colorArr = color_one.split('/');
	let isIncluded = false;
	colorArr.forEach(element => {
		if(color_two.includes(element)){
			isIncluded = true;
		}
	});
	return isIncluded;
};

export const isSameShapeGroup =  (shape_one:string[], shape_two:string[]) => {
	let isIncluded = false;
	shape_one.forEach(element => {
		if(shape_two.includes(element)){
			isIncluded = true;
		}
	});
	return isIncluded;
};