import React, { useEffect, useState, useRef } from 'react';
import { Layout, Menu, Breadcrumb, Dropdown, Button, Card, Form, Input, Select, Checkbox, notification } from 'antd';
import { createHousetype, getHousetypeById, updateHousetype } from 'api/api-housetype';

import { PATHS } from 'constants/routes';

import useOptions from 'modules/options/options.hook';
import { useParams } from 'react-router-dom';

const { Option } = Select;

const Index = () => {

	const params = useParams();	
	const housetypeId = parseInt(params.housetypeId || '0');
	const [form] = Form.useForm();

	const { options: { tags }} = useOptions();

	const [tagGroup, setTagGroup] = useState({state:[], shape:[], region:[]});
	const galleryFormRef = useRef<any>();
	const [isRquesting, setIsRequesting] = useState(false);
	const [housetypeDetail, setHousetypeDetail] = useState<any>({});
    
	useEffect(()=>{
		console.log('tags ==>', tags);
		if(tags){
			const state = tags.filter((ele:any)=>ele.tag_type == 'state');
			const shape = tags.filter((ele:any)=>ele.tag_type == 'shape');
			const region = tags.filter((ele:any)=>ele.tag_type == 'region');
			setTagGroup({ state, shape, region });
		}

	}, [tags]);


	useEffect(()=>{
		console.log('housetypeId ==>', housetypeId);
		if(housetypeId){
			getHousetypeById(housetypeId).then((res:any)=>{
				const formData = res.body;
				formData.step_1_feat_carpet = formData.step_1_feat_carpet == 'no'?false:true;
				formData.step_2_feat_carpet = formData.step_2_feat_carpet == 'no'?false:true;
				formData.active = formData.active == 'y'?true:false;

				formData.tags.forEach((tag:any) => {
					if(tag.tag_type == 'region'){
						formData.region_id = tag.tag_id;
					}
					if(tag.tag_type == 'shape'){
						formData.shape_id = tag.tag_id;
					}
					if(tag.tag_type == 'state'){
						formData.state_id = tag.tag_id;
					}
				});
				console.log('formData ===>', formData);
				form.setFieldsValue(formData);
				setHousetypeDetail(formData);

			}).catch((err:any)=>{
				console.log(err);
			});
		}
	}, [housetypeId]);

	const submitForm = () => {
		form.submit();
	};

	const onSubmitForm = async (values:any) => {
        
		const data = { ...values, tags: [values.state_id, values.region_id, values.shape_id]};

		data.step_1_feat_carpet = data.step_1_feat_carpet?'yes':'no';
		data.step_2_feat_carpet = data.step_2_feat_carpet?'yes':'no';
		data.active = data.active?'y':'n';

		if(galleryFormRef.current){
			const photos = galleryFormRef.current.getPhotos();
			const pIds:any = [];
			photos.forEach((element:any) => {
				pIds.push(element.id);
			});
			data.photos = pIds;
		}

		setIsRequesting(true);

		if(housetypeId == 0){
			try {
				const response = await createHousetype(data);
				notification.open({
					type:'success',
					message: 'Message',
					description:'Created succesfully',
					onClick: () => {
						console.log('Notification Clicked!');
					},
				});
				window.location.href = `${PATHS.DASHBOARD}${PATHS.HOUSETYPES}/${response.body.insertId}`;
			}catch(error:any){
				console.log('error ==>', error);
			}
		}else{
			try {
				await updateHousetype(housetypeId, data);
				notification.open({
					type:'success',
					message: 'Message',
					description:'Updated succesfully',
					onClick: () => {
						console.log('Notification Clicked!');
					},
				});
			}catch(error:any){
				console.log('error ==>', error);
			}
		}
		setIsRequesting(false);
		console.log('onSubmitForm ===>', data);
	};

	return (
		<Card title = {housetypeId > 0?housetypeDetail.title:'New House Type'} extra = {<Button type = "primary" onClick = {submitForm} loading = {isRquesting}>Save</Button>}>
			<Form form={form} labelCol= {{ span: 6 }} wrapperCol = {{ span: 18 }} onFinish = {onSubmitForm}>
				<Form.Item
					label="Title"
					name="title"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Region"
					name="region_id"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Select>
						{
							tagGroup.region.map((ele:any)=>(
								<Option key = {ele.id} value = {ele.id}>{ele.tag_name}</Option>
							))
						}
					</Select>
				</Form.Item>

				<Form.Item
					label="State"
					name="state_id"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Select>
						{
							tagGroup.state.map((ele:any)=>(
								<Option key = {ele.id} value = {ele.id}>{ele.tag_name}</Option>
							))
						}
					</Select>
				</Form.Item>

				<Form.Item
					label="Shape"
					name="shape_id"
					required
					rules={[{ required: true, message: 'required!' }]}
				>
					<Select>
						{
							tagGroup.shape.map((ele:any)=>(
								<Option key = {ele.id} value = {ele.id}>{ele.tag_name}</Option>
							))
						}
					</Select>
				</Form.Item>

				<Form.Item
					label = "Step 1 Feat Carpet"
					name="step_1_feat_carpet"
					required
					valuePropName="checked"
				>
					<Checkbox />
				</Form.Item>

				<Form.Item
					label = "Step 2 Feat Carpet"
					name="step_2_feat_carpet"
					required
					valuePropName="checked"
				>
					<Checkbox />
				</Form.Item>

				<Form.Item
					label="Active"
					name="active"
					valuePropName="checked"
				>
					<Checkbox />
				</Form.Item>
			</Form>
		</Card>
	);
};

export default Index;