import React, { useEffect, useState } from 'react';
import { Table, Tag, Space, Button, Modal, Form, Input, Card, InputNumber, Badge, Select } from 'antd';
import * as PricingAPI from 'api/api-pricing';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import useOptions from 'modules/options/options.hook';

const { Option } = Select;

const confirm = Modal.confirm;

interface Item {
    id: number;
}


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	form:any;
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text' | 'price' | 'select' | 'tag';
    record: Item;
    index: number;
    children: React.ReactNode;
	options?:any[]
}

const EditableCell: React.FC<EditableCellProps> = ({
	form,
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	options,
	...restProps
}) => {

	let inputNode = <Input />;

	if(inputType == 'number'){
		inputNode = <InputNumber min = {0} />;
	}
	if(inputType == 'select'){
		inputNode = <Select>{options?options.map((ele)=><Option value = {ele}>{ele}</Option>):[]}</Select>;
	}

	if(inputType == 'tag'){
		inputNode = <Select>{options?options.map((ele)=><Option key = {ele.id} value = {ele.id}>{ele.tag_name}</Option>):[]}</Select>;
	}

	if(inputType == 'price'){
		inputNode = <Input type = "number" min = {0} suffix = "$"/>;
	}
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{ margin: 0 }}
					rules={[
						{
							required: true,
							message: `Please Input ${title}!`,
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};
const ActionCell = ({ item, editingId, onChange, onEditing, form }: any) => {

	
	const [isRequesting, setIsRequesting] = useState(false);
	

	const onDeleteBtn = () => {
		confirm({
			centered:true,
			title: 'Are you sure you want to delete?',
			okText: 'Delete',
			okType: 'default',
			cancelText: 'No, do not delete',
			okButtonProps:{
				style:{
					backgroundColor:'#fff'
				}
			},
			cancelButtonProps: {
				style:{
					backgroundColor:'#0ab068', color:'#fff'
				}
			},
			onOk() {
				setIsRequesting(true);
				PricingAPI.remove('upgrades', item.id).then((res:any)=>{
					setIsRequesting(false);
					onChange();	
				}).catch((err:any)=>{
					setIsRequesting(false);
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const onClickEditBtn = () => {
		onEditing(item.id);
		form.setFieldsValue(item);
	};

	const onSave = async () => {
		setIsRequesting(true);
		const data = form.getFieldsValue();
		// data.state = data.state.join(',');
		try{
			if(editingId>0){
				await PricingAPI.update('upgrades', editingId, data);
			}else{
				await PricingAPI.create('upgrades', data);
			}
		}catch(err:any){
			console.log(err);
		}
		setIsRequesting(false);
		onEditing(-1);
		form.resetFields();
		onChange();
	};

	const onCancel = () => {
		onEditing(-1);
	};

	if(editingId == item.id){
		return (
			<Space size="middle">
				<Button loading={isRequesting} icon={<CheckCircleOutlined />} onClick={onSave} />
				<Button loading={isRequesting} icon={<CloseCircleOutlined/>} onClick={onCancel} />
			</Space>
		);    
	}

	return (
		<Space size="middle">
			<Button loading={isRequesting} icon={<EditOutlined />} onClick={onClickEditBtn} />
			<Button loading={isRequesting} icon={<DeleteOutlined/>} onClick={onDeleteBtn} />
		</Space>
	);
};

export default function(){


	const [form] = Form.useForm();
	const [editingId, setEditingId] = useState(-1);
	const [tableData, setTableData] = useState<any>([]);
	const [query, setQuery] = useState('');
	const { options: { tags }} = useOptions();

	useEffect(() => {
		if(tags.length > 0){
			loadTable();
		}
	}, [tags]);

	const loadTable = () => {
		PricingAPI.getAll('upgrades').then((res:any)=>{
			
			// res.body.data.forEach((element:any) => {
			// const tempStateTags:any = [];
			// if(element.state){
			// 	element.state.split(',').forEach((tag_id:any) => {
			// 		tags.forEach((tag:any) => {
			// 			if(tag.id == tag_id && tag.tag_type == 'state'){
			// 				tempStateTags.push(tag.id);
			// 			}
			// 		});
			// 	});
			// }
			// element.state = tempStateTags;
			// });

			setTableData(res.body.data);

		}).catch((err:any)=>{
			console.log('ERROR ==>', err);
		});
	};

	const onChangeSearchInput = (e:any) => {
		setQuery(e.target.value);
	};

	const onCreateNewPricingData = () => {
		setEditingId(0);
		const newData = {
			id: 0,
			description:'',
			part_type:0,
			stain_type:'',
			price:0,
			customer:'',
		};
		if(tableData.findIndex((ele:any)=>ele.id == 0) == -1){
			setTableData([newData, ...tableData]);
		}
	};

	const columns = [
		{
			title: 'Builder',
			dataIndex: 'builder_id',
			key: 'builder_id',
			inputType:'tag',
			options: tags.filter((ele:any)=>ele.tag_type == 'builder'),
			editable: true,
			render: (text: string, item: any) => {
				const tag = tags.find((ele:any)=>ele.id == item.builder_id);
				if(tag){
					return tag.tag_name;
				}
				return '';
			}
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			editable:true,
			//fixed:'left',
			sorter: (a:any, b:any) => ('' + a.stair_type).localeCompare(b.title),
		},
		{
			title: 'part_type',
			dataIndex: 'part_type',
			key: 'part_type',
			editable:true,
		},
		{
			title: 'stain_type',
			dataIndex: 'stain_type',
			key: 'stain_type',
			inputType:'select',
			options: ['covergrade', 'staingrade'],
			editable:true,
		},
		// {
		// 	title: 'state',
		// 	dataIndex: 'state_tag_id',
		// 	key: 'state_tag_id',
		// 	inputType:'tag',
		// 	options:tags.filter((ele:any)=>ele.tag_type == 'state'),
		// 	editable:true,
		// 	render:(text:string, item:any)=>{
		// 		const arr:any = [];
		// 		const tagArr = tags.filter((ele:any)=>ele.tag_type == 'state');
		// 		const tag = tagArr.find((tag:any)=>(tag.id == item.state_tag_id));
		// 		if(tag){
		// 			return tag.tag_name;
		// 		}else{
		// 			return null;
		// 		}
		// 	}
		// },
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'price',
			editable:true,
		},
		{
			title: 'Customer',
			dataIndex: 'customer',
			key: 'customer',
			editable:true,
		},
		{
			title: 'Action',
			key: 'action',
			//fixed:'right',
			render: (text: string, record: any) => (
				<ActionCell 
					form = {form}
					item={record}
					editingId = {editingId}  
					onChange={() => loadTable()} 
					onEditing = {(val:number)=>{
						setEditingId(val);
						if(val < 0 && editingId == 0){
							tableData.shift();
							setTableData([...tableData]);
						}
					}}
				/>
			),
		}
	];

	const mapColumns = (col:any) => {
		if (!col.editable) {
			return col;
		}
		const newCol = {
			...col,
			onCell: (record: Item) => ({
				form: form,
				record,
				inputType: (col.inputType)?col.inputType:'text',
				dataIndex: col.dataIndex,
				options:col.options,
				title: col.title,
				editing: (record.id == editingId),
			}),
		};
		if (col.children) {
			newCol.children = col.children.map(mapColumns);
		}
		return newCol;
	};

	const mergedColumns = columns.map(mapColumns);

	const ftableData = tableData.filter((ele:any)=>ele.description.toLowerCase().includes(query.toLowerCase()));

	return(
		<>
			<Card title="Upgrades Pricing" extra = {<><Button onClick={onCreateNewPricingData} type = "primary">Add More</Button></>}>
				<div className ="table-header">
					<Input.Search style={{ width: '40%' }} onChange = {onChangeSearchInput} />
				</div>
				<Form form = {form} >
					<Table
						size="small"
						components={{
							body: {
								cell: EditableCell,
							},
						}}
						bordered
						columns={mergedColumns}
						dataSource={ftableData} 
					/>
				</Form>

			</Card>
		</>
	);
}