import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StateType, UserType } from 'modules/types';
import * as authAction from './options.action';

const useOptions = () => {
	const dispatch = useDispatch();
	const options = useSelector((state: StateType) => {
		return state.optionReducer;
	});

	const getAllTagsSaga = useCallback(() => {
		dispatch(authAction.getAllTagsSaga());
	}, [dispatch]);

	return { options, getAllTagsSaga };
};

export default useOptions;