import { deleteRequest, getRequest, postRequest, putRequest } from './requests';
import config from './config';

export const getAll = (offset, limit, query) => {
	return getRequest(`${config.baseURL}styles/`);
};
export const createStyles = (payload) => {
	return postRequest(`${config.baseURL}styles/`, payload);
};

export const deleteStyles = (id) => {
	return deleteRequest(`${config.baseURL}styles/${id}`);
};

export const updateStyles = (id, payload) => {
	return putRequest(`${config.baseURL}styles/${id}`, payload);
};

export const getStylesById = (id) => {
	return getRequest(`${config.baseURL}styles/ids/${id}`);
};
