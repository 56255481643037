import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { PATHS } from 'constants/routes';
import { Layout, Menu, Breadcrumb, Dropdown, Button } from 'antd';
import {
	UserOutlined,
	DollarCircleOutlined,
	LaptopOutlined,
	LogoutOutlined,
	PictureFilled,
	TagsOutlined,
	GiftOutlined,
	SketchOutlined,
	HomeOutlined,
	TableOutlined,
} from '@ant-design/icons';

import useAuth from 'modules/auth/auth.hook';

import classes from './style.module.scss';
import useOptions from 'modules/options/options.hook';

const { SubMenu } = Menu;

const { Header, Content, Sider, Footer } = Layout;

const Dashboard = () => {
	const { getAllTagsSaga } = useOptions();

	useEffect(() => {
		getAllTagsSaga();
	}, []);

	let openMenuItem = '0';
	if (location.pathname.includes(PATHS.BUILDERS)) openMenuItem = '0';
	if (location.pathname.includes(PATHS.USERS)) openMenuItem = '1';
	if (location.pathname.includes(PATHS.TAGS)) openMenuItem = '2';
	if (location.pathname.includes(PATHS.HOUSETYPES)) openMenuItem = '3';
	if (location.pathname.includes(PATHS.STYLES)) openMenuItem = '4';
	if (location.pathname.includes(PATHS.MATERIALS)) openMenuItem = '5';
	if (location.pathname.includes(PATHS.PRICING)) openMenuItem = '6';

	const { profile, onSignOut } = useAuth();

	const [breadcrumbs, setBreadcrumbs] = useState<string[]>([PATHS.DASHBOARD, PATHS.BUILDERS]);

	const linkTo = (paths: string[]) => {
		window.location.href = paths.join('');
		setBreadcrumbs(paths);
	};

	const onClickLogoutBtn = () => {
		onSignOut();
		window.location.href = PATHS.LOGIN;
	};

	const menu = (
		<Menu>
			<Menu.Item key="1">
				<div onClick={onClickLogoutBtn}>
					<LogoutOutlined /> Logout
				</div>
			</Menu.Item>
		</Menu>
	);

	return (
		<Layout>
			<Header className={classes.header}>
				<div className="logo">
					<a href="#">
						<img src="/McDonald-Jones-logo-white.png" width = "100" />
					</a>
				</div>
				<Dropdown overlay={menu} placement="bottomLeft">
					<Button>{profile.display_name}</Button>
				</Dropdown>
			</Header>
			<Layout className={classes.layout}>
				<Sider className={classes.sider}>
					<Menu
						theme="dark"
						mode="inline"
						defaultSelectedKeys={[openMenuItem]}
						defaultOpenKeys={[openMenuItem]}
					>
						<Menu.Item
							key="0"
							icon={<SketchOutlined />}
							onClick={() => {
								linkTo([PATHS.DASHBOARD, PATHS.BUILDERS]);
							}}
						>
							Displays
						</Menu.Item>
						<Menu.Item
							key="1"
							icon={<UserOutlined />}
							onClick={() => {
								linkTo([PATHS.DASHBOARD, PATHS.USERS]);
							}}
						>
							Users
						</Menu.Item>
						<Menu.Item
							key="2"
							icon={<TagsOutlined />}
							onClick={() => {
								linkTo([PATHS.DASHBOARD, PATHS.TAGS]);
							}}
						>
							Tags
						</Menu.Item>
						<Menu.Item
							key="3"
							icon={<HomeOutlined />}
							onClick={() => {
								linkTo([PATHS.DASHBOARD, PATHS.HOUSETYPES]);
							}}
						>
							House Types
						</Menu.Item>
						<Menu.Item
							key="4"
							icon={<PictureFilled />}
							onClick={() => {
								linkTo([PATHS.DASHBOARD, PATHS.STYLES]);
							}}
						>
							Styles
						</Menu.Item>
						<Menu.Item
							key="5"
							icon={<TableOutlined />}
							onClick={() => {
								linkTo([PATHS.DASHBOARD, PATHS.MATERIALS]);
							}}
						>
							Materials
						</Menu.Item>
						<SubMenu key="6" icon={<DollarCircleOutlined />} title="Pricing">
							<Menu.Item
								key="6-1"
								onClick={() => {
									linkTo([PATHS.DASHBOARD, PATHS.PRICING_BALUSTRADE]);
								}}
							>
								Balustrade
							</Menu.Item>
							<Menu.Item
								key="6-2"
								onClick={() => {
									linkTo([PATHS.DASHBOARD, PATHS.PRICING_STAIR]);
								}}
							>
								Stairs
							</Menu.Item>
							<Menu.Item
								key="6-3"
								onClick={() => {
									linkTo([PATHS.DASHBOARD, PATHS.PRICING_STAIN]);
								}}
							>
								Stain
							</Menu.Item>
							<Menu.Item
								key="6-4"
								onClick={() => {
									linkTo([PATHS.DASHBOARD, PATHS.PRICING_UPGRADES]);
								}}
							>
								Upgrades
							</Menu.Item>
							<Menu.Item
								key="6-5"
								onClick={() => {
									linkTo([PATHS.DASHBOARD, PATHS.PRICING_TRAVEL]);
								}}
							>
								Travel
							</Menu.Item>
						</SubMenu>
					</Menu>
				</Sider>
				<Layout style={{ padding: '0 24px 24px', marginLeft: 200 }}>
					<Breadcrumb style={{ margin: '16px 0', textTransform: 'capitalize' }}>
						{breadcrumbs.map((e, i) => (
							<Breadcrumb.Item key={i}>{e.replace('/', '')}</Breadcrumb.Item>
						))}
					</Breadcrumb>
					<Content className={classes.siteLayoutBackground}>
						<Outlet />
					</Content>
					<Footer style={{ textAlign: 'center' }}>SA Stairs + MJH Spec Site ©2024</Footer>
				</Layout>
			</Layout>
		</Layout>
	);
};

export default Dashboard;
