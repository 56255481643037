import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
// import blockchain from "./blockchain/blockchain.reducer";
// import blockchain from "./blockchain/blockchain.reducer";
import authReducer from './auth/auth.reducer';
import watchAuthSaga from './auth/auth.saga';
import optionReducer from './options/options.reducer';
import watchoptionsSaga from './options/options.saga';

import galleryReducer from './gallery/gallery.reducer';



const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
	authReducer,
	optionReducer,
	galleryReducer
});

const store = createStore(
	rootReducer,
	undefined,
	compose(
		applyMiddleware(sagaMiddleware),
		window.devToolsExtension ? window.devToolsExtension() : (f) => f,
	),
);

sagaMiddleware.run(watchAuthSaga);
sagaMiddleware.run(watchoptionsSaga);

export default store;
