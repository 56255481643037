import * as actions from './auth.action';
import { takeEvery, put, call } from 'redux-saga/effects';
import { loginByToken, sigin } from 'api/api-auth';

import { PATHS } from 'constants/routes';

function* signInRequest(data: any) {
	const { email, password } = data.payload;
	console.log('signInRequest ====>', email, password);
	let response:{body:any};
	try {
		response = yield call(sigin, { email, password });
		yield put(actions.setProfile(response.body));
		window.location.href = `${PATHS.DASHBOARD}${PATHS.BUILDERS}`;
	} catch (e) {
		console.log(e);
	}
}

function* loginByTokenRequest() {
	try {
		const response:{body:any} = yield call(loginByToken);
		yield put(actions.setProfile(response.body));
	} catch (e) {
		console.log(e);
	}
}


export default function* watchAuthSaga() {
	// yield fork(loginWatcherSaga);
	yield takeEvery(actions.signInRequestSaga, signInRequest);
	// yield takeEvery(actions.signOutSaga, signOut);
	yield takeEvery(actions.loginByTokenSaga, loginByTokenRequest);
}
