import React, { useEffect, useState, useRef } from 'react';
import { Layout, Menu, Breadcrumb, Dropdown, Button, Card, Form, Input, Select, Checkbox, notification, Badge, Divider } from 'antd';
import { createMaterial, updateMaterial } from 'api/api-materials';
import PhotoPicker from 'components/PhotoPicker';
import MetaDataForm from './MetaDataForm';


import { PATHS } from 'constants/routes';

import useOptions from 'modules/options/options.hook';
import useGallery from 'modules/gallery/gallery.hook';

const { Option } = Select;

interface Props {
	data:any,
	onChange: (newId?:any)=>void
}

const Index = ({ data, onChange }:Props) => {

	const [form] = Form.useForm();
	const [isRquesting, setIsRequesting] = useState(false);

	useEffect(()=>{
		form.setFieldsValue(data);
	}, [data]);

	const submitForm = () => {
		form.submit();
	};

	const onSubmitForm = async (values:any) => {
		setIsRequesting(true);
		try {
			await updateMaterial(data.ID, values);
			notification.open({
				type:'success',
				message: 'Message',
				description:'updated succesfully',
				onClick: () => {
					console.log('Notification Clicked!');
				},
			});
		}catch(error:any){
			console.log('error ==>', error);
		}
		setIsRequesting(false);
		console.log('onSubmitForm ===>', values);
		onChange();

	};


	return (
		<Form form={form} labelCol= {{ span: 6 }} wrapperCol = {{ span: 18 }} onFinish = {onSubmitForm}>
			
			<Form.Item
				label="Material ID"
				name="ID"
			>
				<Input disabled />
			</Form.Item>

			<Form.Item
				label="Metadata Material"
				name="Material"
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="Metadata Group"
				name="Group"
			>
				<Input disabled />
			</Form.Item>

			<Form.Item
				label="Metadata Part"
				name="Part"
			>
				<Input disabled />
			</Form.Item>

			<Divider />

			<Form.Item
				label="Title"
				name="title"
				required
				rules={[{ required: true, message: 'required!' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Description"
				name="description"
				rules={[{ required: true, message: 'required!' }]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="Icon"
				name="photo_id"
				required
				rules={[{ required: true, message: 'required!' }]}
			>
				<PhotoPicker photo = {data.photo} onChangePhoto = {(photo:any)=>{
					form.setFieldsValue({photo_id: photo.id});
				}} />
			</Form.Item>
			

			<div style = {{textAlign:'right'}}>
				<Button type = "primary" onClick = {submitForm} loading = {isRquesting}>Save</Button>
			</div>
		</Form>
	);
};

export default Index;