import React, { useEffect } from 'react';
import { Form, Input, Button, Checkbox, Card } from 'antd';
import useAuth from 'modules/auth/auth.hook';
import classes from './style.module.scss';

import { PATHS } from 'constants/routes';


const LoginPage = () => {
	const { profile, onSignin } = useAuth();

	useEffect(()=>{
		// if(profile.user_token){
		// 	window.location.href(`${PATHS.DASHBOARD}${PATHS.USER_MANAGEMENT}`);
		// }
	}, [profile.user_token]);
	const onFinish = (values: any) => {
		onSignin(values);
	};	

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<div className={classes.container}>
			<Form initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<Card title="Admin Login" style={{ width: 500 }}>
					<Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
						<Input />
					</Form.Item>

					<Form.Item
						name="password"
						rules={[{ required: true, message: 'Please input your password!' }]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item name="remember" valuePropName="checked">
						<Checkbox>Remember me</Checkbox>
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit">
							Login
						</Button>
					</Form.Item>
				</Card>
			</Form>
		</div>
	);
};

export default LoginPage;
