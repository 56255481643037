import React, { useEffect, useState } from 'react';
import { Table, Tag, Space, Button, Modal, Form, Input, Card, InputNumber, Badge, Select } from 'antd';
import * as PricingAPI from 'api/api-pricing';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import classes from './style.module.scss';
import { formatPrice } from 'utils';
import useOptions from 'modules/options/options.hook';

const { Option } = Select;

const confirm = Modal.confirm;

interface Item {
    id: number;
}


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	form:any;
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text' | 'price' | 'select' | 'tag' |'feature_tag';
    record: Item;
    index: number;
    children: React.ReactNode;
	options?:any[]
}

const EditableCell: React.FC<EditableCellProps> = ({
	form,
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	options,
	...restProps
}) => {

	let inputNode = <Input />;

	if(inputType == 'number'){
		inputNode = <InputNumber min = {0} />;
	}
	if(inputType == 'select'){
		inputNode = <Select>{options?options.map((ele)=><Option value = {ele}>{ele}</Option>):[]}</Select>;
	}

	if(inputType == 'feature_tag'){
		inputNode = <Select mode="multiple">{options?options.map((ele)=><Option key = {ele} value = {ele}>{ele}</Option>):[]}</Select>;
	}

	if(inputType == 'tag'){
		inputNode = <Select>{options?options.map((ele)=><Option key = {ele.id} value = {ele.id}>{ele.tag_name}</Option>):[]}</Select>;
	}

	if(inputType == 'price'){
		inputNode = <Input type = "number" min = {0} suffix = "$"/>;
	}
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{ margin: 0 }}
					rules={[
						{
							required: true,
							message: `Please Input ${title}!`,
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};
const ActionCell = ({ item, editingId, onChange, onEditing, form }: any) => {

	
	const [isRequesting, setIsRequesting] = useState(false);
	const onDeleteBtn = () => {
		confirm({
			centered:true,
			title: 'Are you sure you want to delete?',
			okText: 'Delete',
			okType: 'default',
			cancelText: 'No, do not delete',
			okButtonProps:{
				style:{
					backgroundColor:'#fff'
				}
			},
			cancelButtonProps: {
				style:{
					backgroundColor:'#0ab068', color:'#fff'
				}
			},
			onOk() {
				setIsRequesting(true);
				PricingAPI.remove('stair', item.id).then((res:any)=>{
					setIsRequesting(false);
					onChange();	
				}).catch((err:any)=>{
					setIsRequesting(false);
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const onClickEditBtn = () => {
		onEditing(item.id);
		form.setFieldsValue(item);
	};

	const onSave = async () => {
		setIsRequesting(true);
		console.log('onSave ==>', form.getFieldsValue());

		const data = form.getFieldsValue();
		data.feature_variants = data.feature_variants.join(',');

		try{
			if(editingId>0){
				await PricingAPI.update('stair', editingId, data);
			}else{
				await PricingAPI.create('stair', data);
			}
		}catch(err:any){
			console.log(err);
		}
		setIsRequesting(false);
		onEditing(-1);
		form.resetFields();
		onChange();
	};

	const onCancel = () => {
		onEditing(-1);
	};

	if(editingId == item.id){
		return (
			<Space size="middle">
				<Button loading={isRequesting} icon={<CheckCircleOutlined />} onClick={onSave} />
				<Button loading={isRequesting} icon={<CloseCircleOutlined/>} onClick={onCancel} />
			</Space>
		);    
	}

	return (
		<Space size="middle">
			<Button loading={isRequesting} icon={<EditOutlined />} onClick={onClickEditBtn} />
			<Button loading={isRequesting} icon={<DeleteOutlined/>} onClick={onDeleteBtn} />
		</Space>
	);
};

export default function(){


	const [form] = Form.useForm();
	const [editingId, setEditingId] = useState(-1);
	const [tableData, setTableData] = useState<any>([]);
	const [query, setQuery] = useState('');

	const { options: { tags }} = useOptions();

	useEffect(()=>{
		loadTable();
	}, []);

	const loadTable = () => {
		PricingAPI.getAll('stair').then((res:any)=>{
			console.log('PricingAPI.getAll ===>', res.body);
			res.body.data.forEach((element:any) => {
				element.feature_variants = element.feature_variants.split(',');
			});
			setTableData(res.body.data);
		}).catch((err:any)=>{
			console.log('ERROR ==>', err);
		});
	};

	const onChangeSearchInput = (e:any) => {
		setQuery(e.target.value);
	};

	const onCreateNewPricingData = () => {
		setEditingId(0);
		const newData = {
			id: 0,
			stair_type:'',
			feature_variants:[],
		};
		if(tableData.findIndex((ele:any)=>ele.id == 0) == -1){
			setTableData([newData, ...tableData]);
		}
	};

	const columns = [
	
		{
			title: 'Classification',
			className: classes.classification,
			children: [
				{
					title: 'Builder',
					dataIndex: 'builder_id',
					key: 'builder_id',
					inputType:'tag',
					options: tags.filter((ele:any)=>ele.tag_type == 'builder'),
					editable: true,
					render: (text: string, item: any) => {
						const tag = tags.find((ele:any)=>ele.id == item.builder_id);
						if(tag){
							return tag.tag_name;
						}
						return '';
					}
				},
				{
					title: 'Stair Type',
					dataIndex: 'stair_type',
					key: 'stair_type',
					editable:true,
					//fixed:'left',
					sorter: (a:any, b:any) => ('' + a.stair_type).localeCompare(b.title),
				},
				// {
				// 	title: 'state',
				// 	dataIndex: 'state_tag_id',
				// 	key: 'state_tag_id',
				// 	inputType:'tag',
				// 	options:tags.filter((ele:any)=>ele.tag_type == 'state'),
				// 	editable:true,
				// 	render:(text:string, item:any)=>{
				// 		const arr:any = [];
				// 		const tagArr = tags.filter((ele:any)=>ele.tag_type == 'state');
				// 		const tag = tagArr.find((tag:any)=>(tag.id == item.state_tag_id));
				// 		if(tag){
				// 			return tag.tag_name;
				// 		}else{
				// 			return null;
				// 		}
				// 	}
				// },	
				{
					title: 'Finish',
					dataIndex: 'finish',
					key: 'finish',
					inputType: 'select',
					options: ['none', 'covergrade', 'staingrade', 'laminate'],
					editable:true,
				}
			],
			editable:true,
		},
		{
			title: 'Configuration',
			className: classes.configuration,
			children: [
				{
					title: 'Stair',
					dataIndex: 'config_stair',
					key: 'config_stair',
					// inputType:'select',
					// options: ['No', 'Yes 1', 'Yes 2'],
					editable:true,
				},
				{
					title: 'Tags',
					dataIndex: 'config_tags',
					key: 'config_tags',
					editable:true,
				},
				{
					title: 'Tread Finish',
					dataIndex: 'config_tread_finish',
					key: 'config_tread_finish',
					editable:true,
				},
				{
					title: 'Riser Finish',
					dataIndex: 'config_riser_finish',
					key: 'config_riser_finish',
					editable:true,
				},
				{
					title: 'Left Stringer Finish',
					dataIndex: 'config_left_stringer_finish',
					key: 'config_left_stringer_finish',
					editable:true,
				},
				{
					title: 'Right Stringer Finish',
					dataIndex: 'config_right_stringer_finish',
					key: 'config_right_stringer_finish',
					editable:true,
				},
				{
					title: 'Margin Finish',
					dataIndex: 'config_margin_finish',
					key: 'config_margin_finish',
					editable:true,
				}
			],
			editable:true,
		},
		{
			title: 'Pricing',
			className: classes.pricing,
			children: [
				// {
				// 	title: 'Straight',
				// 	dataIndex: 'straight',
				// 	key: 'straight',
				// 	inputType:'price',
				// 	editable:true,
				// },
				// {
				// 	title: 'Shape',
				// 	dataIndex: 'shape',
				// 	key: 'shape',
				// 	editable:true,
				// },
				{
					title: 'Price',
					dataIndex: 'price',
					key: 'price',
					inputType:'price',
					editable:true,
					render:(text:string, item:any)=>{
						return formatPrice(Number(text));
					}
				},
				{
					title: 'Shape Variants',
					dataIndex: 'feature_variants',
					key: 'feature_variants',
					inputType:'feature_tag',
					options: [
						'Straight',
						'U Shape Landing (A)',
						'U Shape Landing (B)', 
						'1-4 Transition',
						'1-4 Transition L',
						'1-4 Transition W',
						'Straight - WR only',
						'Straight Dim',
					],
					editable:true,
					render:(text:string, item:any)=>{
						const arr:any = [];
						return item.feature_variants.join(',');
					}
				}
			],
			editable:true,
		},
		{
			title: 'ACTIONS',
			className: classes.tableActions,
			children: [
				{
					title: 'Action',
					key: 'action',
					//fixed:'right',
					render: (text: string, record: any) => (
						<ActionCell 
							form = {form}
							item={record}
							editingId = {editingId}  
							onChange={() => loadTable()} 
							onEditing = {(val:number)=>{
								setEditingId(val);
								if(val < 0 && editingId == 0){
									tableData.shift();
									setTableData([...tableData]);
								}
							}}
						/>
					),
				},
			],
			editable:true,
		},
	];

	const mapColumns = (col:any) => {
		if (!col.editable) {
			return col;
		}
		const newCol = {
			...col,
			onCell: (record: Item) => ({
				form: form,
				record,
				inputType: (col.inputType)?col.inputType:'text',
				dataIndex: col.dataIndex,
				options:col.options,
				title: col.title,
				editing: (record.id == editingId),
			}),
		};
		if (col.children) {
			newCol.children = col.children.map(mapColumns);
		}
		return newCol;
	};

	const mergedColumns = columns.map(mapColumns);

	const ftableData = tableData.filter((ele:any)=>ele.stair_type.toLowerCase().includes(query.toLowerCase()));

	return(
		<>
			<Card title="Stair Pricing" extra = {<><Button onClick={onCreateNewPricingData} type = "primary">Add More</Button></>}>
				<div className ="table-header">
					<Input.Search style={{ width: '40%' }} onChange = {onChangeSearchInput} />
				</div>
				<Form form = {form} >
					<Table
						size="small"
						components={{
							body: {
								cell: EditableCell,
							},
						}}
						bordered
						scroll = {{x:'2000px'}}
						columns={mergedColumns}
						dataSource={ftableData} 
					/>
				</Form>

			</Card>
		</>
	);
}