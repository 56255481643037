import React, { useImperativeHandle, useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Select, Row, Col, Table, Space } from 'antd';
import { DeleteFilled, EditFilled, InfoCircleOutlined } from '@ant-design/icons';
import config from 'api/config';
import GalleryDialog from 'components/GalleryDialog';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const SortableItem:any = SortableElement((props:any) => <div {...props} />);
const SortableContainerList:any = SortableContainer((props:any) => <div {...props} /> );

const Index = React.forwardRef((props: { photoList:any[] }, ref) => {


	const [photos, setPhotos] = useState<any[]>([]);

	const [visiblePhotoPicker, setVisiblePhotoPicker] = useState(false);

	useEffect(()=>{
		setPhotos(props.photoList);
	}, [props.photoList]);


	useImperativeHandle(ref, () => (
		{
			getPhotos: () => {
				return photos;
			}
		}
	), [photos.length]);
    

	const [editingIndex, setEditingIndex] = useState(-1);

	const deleteImage = (index:number) => {
		const newArr = [...photos];
		newArr.splice(index, 1);
		setPhotos(newArr);
	};

	const onClose = () => {
		setVisiblePhotoPicker(false);
	};

	const onSelect = (pr:any) => {
		if(editingIndex == -1){
			setPhotos([...photos, ...pr]);
		}else{
			const newArr = [...photos];
			newArr[editingIndex] = pr[0];
			setPhotos(newArr);
		}
		setVisiblePhotoPicker(false);
		setEditingIndex(-1);
	};

	const onSortEnd = ({oldIndex, newIndex}:any) => {
		setPhotos(arrayMoveImmutable(photos, oldIndex, newIndex));
	};

	console.log('photos ==>', photos);

	return (
		<>
			<Form.Item label="Thumbnails" tooltip={{ title: 'Fixed height of 600px but width can vary', icon: <InfoCircleOutlined /> }}>
				<SortableContainerList axis = "xy" onSortEnd = {onSortEnd} >
					{photos.map((photo:any, index:number)=>(
						<SortableItem className = "imagePreview" key = {index} index = {index}>
							<img src = {`${config.host}${photo.url}`} />
							<div className = "actionBar">
								<span>
									<EditFilled onClick = {()=>{
										setEditingIndex(index);
										setVisiblePhotoPicker(true);
									}} style = {{color:'#fff'}} />
								</span>
								<span>
									<DeleteFilled style = {{color:'#fff'}} onClick = {()=>deleteImage(index)}/>
								</span>
							</div>
						</SortableItem>
					))}
					<div className = "imagePreview">
						<Button type = "default" onClick={()=>{
							setVisiblePhotoPicker(true);
						}} style = {{width:'100%', height:'100%'}}>Add Image</Button>
					</div>
				</SortableContainerList>
			</Form.Item>

			<GalleryDialog open = {visiblePhotoPicker} onClose = {onClose} onSelect = {onSelect}/>
		</>
	);
});

export default Index;