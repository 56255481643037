export const PATHS = {
	LOGIN: '/',
	DASHBOARD: '/dashboard',
	BUILDERS: '/model-homes',
	USERS: '/users',
	TAGS: '/tags',
	HOUSETYPES: '/house-types',
	MATERIALS: '/materials',
	STYLES: '/styles',
	PRICING: '/pricing',
	PRICING_BALUSTRADE: '/pricing/balustrade',
	PRICING_STAIR: '/pricing/stair',
	PRICING_STAIN: '/pricing/stain',
	PRICING_UPGRADES: '/pricing/upgrades',
	PRICING_TRAVEL: '/pricing/travel',
};
