import React, { useEffect } from 'react';
import useAuth from 'modules/auth/auth.hook';

import { PATHS } from 'constants/routes';

export const AuthLayout = () => {
	const { profile, loginByToken, onSignOut } = useAuth();
	useEffect(() => {
		if (profile.user_token) {
			loginByToken();
		} else {
			// window.location.href = PATHS.LOGIN;
		}		
	}, []);

	return <div />;
};
